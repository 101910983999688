<template>
  <v-row justify="center">
    <v-dialog
      v-model="showForm"
      hide-overlay
      width="800px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-container class="pa-0">
        <v-card class="pb-10">
          <form-header
            :title="title"
            :form="form"
            :permissions="permissions"
            :view="view"
            @viewChange="viewChanged"
            @saveForm="saveForm"
            @disableRecord="change_status"
            @newForm="newForm"
            @close="close"
            @cancelForm="cancelForm"
            @edit="view = 'edit'"
          />
          <v-divider />
          <v-card-text>
            <el-form
              :model="form"
              :rules="rules"
              ref="form"
              label-width="120px"
              label-position="top"
            >
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="English Name" prop="name">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.name"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item
                    label="Dhivehi Name"
                    prop="dhivehi_name"
                    style="text-align: right"
                  >
                    <span slot="label" class="thaana">ދިވެހި ނަން</span>
                    <dhivehi-field
                      v-model="form.dhivehi_name"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Country" prop="countrys_id">
                    <select-list
                      v-model="form.countrys_id"
                      :readonly="true"
                      custom-url="client-list/countrys"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Atoll/State" prop="states_id">
                    <select-list
                      v-model="form.states_id"
                      :readonly="true"
                      :extraid="form.countrys_id"
                      custom-url="client-list/states"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-3 col-sm-12">
                  <el-form-item label="Island/City" prop="citys_id">
                    <select-list
                      v-model="form.citys_id"
                      :readonly="true"
                      :extraid="form.states_id"
                      custom-url="client-list/citys"
                      :disabled="view != 'new' && view != 'edit'"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="Road Name" prop="road">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.road"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-4 col-sm-12">
                  <el-form-item label="District Name" prop="district">
                    <el-input
                      size="mini"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.district"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Latitude" prop="lat">
                    <el-input-number
                      size="mini"
                      :controls="false"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.lat"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-2 col-sm-12">
                  <el-form-item label="Longitude" prop="lng">
                    <el-input-number
                      size="mini"
                      :controls="false"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.lng"
                    />
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Description" prop="description">
                    <el-input
                      size="mini"
                      type="textarea"
                      :rows="5"
                      :disabled="view != 'new' && view != 'edit'"
                      v-model="form.description"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-lg-6 col-sm-12">
                  <el-form-item label="Status" prop="status">
                    <el-input
                      size="mini"
                      :disabled="true"
                      v-model="form.status"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
            </el-form>
          </v-card-text>
          <!--<v-divider />-->
        </v-card>
        <v-overlay :value="loading" :opacity="0.7">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
      </v-container>
    </v-dialog>
  </v-row>
</template>
<script>
import Vue from "vue";
import {
  Button,
  Form,
  FormItem,
  Input,
  InputNumber,
  Option,
  Select,
} from "element-ui";
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Button);
Vue.use(Input);
Vue.use(Select);
Vue.use(Option);
Vue.use(InputNumber);
import FormHeader from "@/components/custom/FormHeader.vue";
import DhivehiField from "@/components/custom/DhivehiField.vue";
import SelectList from "@/components/custom/SelectList.vue";
export default {
  watch: {
    show: function () {
      this.showForm = this.show;
      this.permissions = this.perm;
      if (this.showForm) {
        if (this.empty(this.data)) {
          this.newForm();
        } else {
          this.view = "view";
        }
      }
    },
    data: function () {
      if (!this.empty(this.data)) {
        this.getRecord();
      }
    },
    perm: function () {
      this.permissions = this.perm;
    },
  },
  props: {
    data: null,
    title: {
      type: String,
      default: "",
    },
    end_point: {
      type: String,
      default: "",
    },
    show: {
      type: Boolean,
      default: false,
    },
    perm: null,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  newd() {},
  components: {
    FormHeader,
    DhivehiField,
    SelectList,
  },
  data() {
    return {
      form: {},
      view: "view",
      rules: {
        name: [
          { required: true, message: "Please enter Name", trigger: "blur" },
        ],
        countrys_id: [
          {
            required: true,
            message: "Please select  Country",
            trigger: "blur",
          },
        ],
        states_id: [
          { required: true, message: "Please select  State", trigger: "blur" },
        ],
        citys_id: [
          { required: true, message: "Please select  City", trigger: "blur" },
        ],
        road: [
          { required: true, message: "Please enter Road", trigger: "blur" },
        ],
      },
      permissions: [],
      loading: false,
      showForm: false,
    };
  },
  methods: {
    saveForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          return false;
        }
      });
    },
    submit() {
      this.loading = true;
      var api = "create";
      if (!this.empty(this.form.id)) api = "update";
      this.api(this.end_point + api, this.form)
        .then((response) => {
          if (response.status == true) {
            this.loading = false;
            this.form = response.data;
            this.view = "view";
            this.showInfo("Record Saved successfully");
            this.$emit("updated");
            if (this.popup) this.close();
          } else {
            this.showError(response.error);
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change_status(enable) {
      var api = "enable";
      if (!enable) api = "disable";
      this.loading = true;
      this.api(this.end_point + api, this.form)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    newForm() {
      this.form = {
        id: null,
        clients_id: null,
        name: null,
        dhivehi_name: null,
        countrys_id: null,
        states_id: null,
        citys_id: null,
        road: null,
        district: null,
        lat: null,
        lng: null,
        description: null,
        status: "Active",
      };
      this.view = "new";
    },
    getRecord() {
      let f = {
        id: this.data.id,
      };
      this.loading = true;
      this.api(this.end_point + "record", f)
        .then((response) => {
          this.loading = false;
          this.form = response.data;
          this.permissions = response.permissions;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("close");
    },
    cancelForm() {
      this.view = "view";
      if (this.popup) this.close();
      if (this.empty(this.form.id)) this.close();
    },
    viewChanged(val) {
      this.view = val;
    },
  },
  name: "EventLocationsForm",
};
</script>
